/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.css';
require('../less/base.less');

// Need jQuery? Install it with "yarn add jquery", then uncomment to import it.
// import $ from 'jquery';

// const jQuery = require('jquery');
// const $ = require('jquery');
// require('bootstrap');

// import the function from greet.js (the .js extension is optional)
// ./ (or ../) means to look for a local file
// var greet = require('./greet');
// var greet = require('./articles');

// $(document).ready(function() {
// console.log("a");


//     // ON PAGE: "/manage-articles"
//     // Actually I should not include it in the general js file, but I don't have time rn
// 	$(".choose-articles-publish-horn-checkbox").on("click", function(){
// 		var articlesChosen = false;
// 		// $(".choose-articles-publish-horn-checkbox").each(function(){
// 		// 	var chosen = $(this).is(":checked");
// 		// 	if(chosen){
// 		// 		articlesChosen = true;
// 		// 	}
// 		// })

// 		// if(articlesChosen){
// 		$("#choose-articles-publish-horn").removeClass("disabled");
// 		// } else {
// 			// $("#choose-articles-publish-horn").addClass("disabled");
// 		// }		
// 	})

// 	$("body").on("submit", "form", function() {
// 	    $(this).submit(function() {
// 	        return false;
// 	    });
// 	    return true;
// 	});	

// 	if( $("input[name=horn_hide]").length > 0 ){
// 		$(".horn_hide").remove();
// 		$(".article-preview-unlock-window").addClass("active");
// 		$(".w-custom-iframe").css({"padding-bottom" : "30px"});
// 	}

//     $('input[type=file]').on('change',function(){
//         //get the file name
//         var fileName = $(this).val();
//         //replace the "Choose a file" label

//         $(this).next('.custom-file-label').html(fileName);
//     })


// 	$(".delete-article").on("click", function(e){

// 		var idToDelete = $(this).data("id");
// 		$("#article_to_delete").val( idToDelete );

// 		if (!confirm("Möchten Sie den Artikel wirklich löschen?")) {
// 			e.preventDefault();
// 		} else {
// 			console.log("Alles klar");
// 		}		
// 	});



// 	$(".delete_ad").on("click", function(e){

// 		var idToDelete = $(this).data("id");
// 		$("#ad_to_delete").val( idToDelete );

// 		if (!confirm("Möchten Sie die Anzeige wirklich löschen?")) {
// 			e.preventDefault();
// 		} else {
// 			console.log("Alles klar");
// 		}		
// 	});






// 	// $("#create_article_video_article_checkbox").click(function(){
// 	// 	$("#w-video_article").toggle("show");
// 	// })


// });
